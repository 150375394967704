import { api, i18n } from "@/config"
import { showSuccessToast, showToast } from "@/helpers/toasts"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { mapFilters } from "./index"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    const { data } = await api.get(`${baseURI}/${id}/edit`, { errorRedirect: true })
    commit("SET_ITEM", data.data)
  },

  CREATE_ITEM: async ({ commit }, params) => {
    try {
      await api.post(baseURI, { user: params })
      commit("SET_INVALID_FIELDS", [])
    } catch (e) {
      showToast({ text: i18n.t("activerecord.errors.models.user.could_not_create") })
    }
  },

  DELETE_ITEM: async ({ commit, state: { pagination, sorting, filters } }, id) => {
    try {
      const params = {
        pagination,
        sorting: normalizeSortingParams(sorting),
        filters: mapFilters(filters)
      }
      const { data } = await api.delete(`${baseURI}/${id}`, { params })
      const { items, meta } = data.data
      commit("SET_ITEMS", items)
      commit("SET_PAGINATION_DATA", meta.pagination)
    } catch ({ response: { status } }) {
      if (status === 404) showToast({ text: i18n.t("activerecord.errors.models.user.not_found") })
    }
  },

  UPDATE_ITEM: async (_, { id, ...params }) => {
    try {
      await api.patch(`${baseURI}/${id}`, { user: params })
      showSuccessToast({ text: i18n.t("account_management.create_account.account_updated") })
    } catch (e) {
      showToast({ text: i18n.t("activerecord.errors.models.user.could_not_update") })
    }
  },

  CHECK_ACCOUNT_ID_EXISTS: (_, account_id) => {
    return api.post(`${baseURI}/account_exists`, { account_id })
  }
})
